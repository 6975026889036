import React, { FunctionComponent, useState } from "react";

import "./Errors.scss";

interface IErrorProps {
	msg?: string;
	errorCode?: number;
}

export const Errors: FunctionComponent<IErrorProps> = (props: IErrorProps) => {

   const [msg, setMsg] = useState(props.msg || `An error has occured`);

	const { errorCode } = props;

	const loadErrorMessage = () => {
		return `Error ${errorCode} - ${msg}`;
	};

	return (
		<div className="errors-container">
			<div className="o4c card errors">
				<div className="header">
					<div>
						<i className="icon-alert leading" />{" "}
						{"Something went wrong"}
					</div>
				</div>
				<div className="main">
					{errorCode ? (
						<p>
							<strong>{loadErrorMessage()}</strong>
						</p>
					) : (
						<p>
							<strong>{msg}</strong>
						</p>
					)}
					<p>
						{
							"Please contact your system administrator to solve this issue."
						}
					</p>
				</div>
			</div>
		</div>
	);
};
