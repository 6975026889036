import { FunctionComponent } from "react"
import moment from "moment"

import { SynergyTask } from "src/interfaces/synergyTask"
import { WorkflowTask } from "../../components/workflowTask/WorkflowTask"

import "./WorkflowView.scss"
import { EmptyTaskList } from "../../components/emptyTaskList/EmptyTaskList"

interface IWorkflowViewProps {
   tasks: SynergyTask[]
   prefix: string
}

export const WorkflowView: FunctionComponent<IWorkflowViewProps> = (
   props: IWorkflowViewProps
) => {

   const {tasks, prefix} = props;


   const renderTaskList = () => {
      if(tasks.length === 0) {
         return <EmptyTaskList />
      }
      

      return tasks.map(t => {
         return (
            <>
            <WorkflowTask
               key={t.ID}
               date={moment(t.Created).format("DD/MM/YYYY")}
               activity={t.Action}
               title={t.Type}
               description={t.Description}
               externalLink={`${prefix}?BCAction=1&ID={${t.ID}}`}
               />
            </>
         )
      })
   }

   return (
      <div className="workflow-list">
         {renderTaskList()}
      </div>
   )
}