import { Pill } from "@o4c/plugin-components";
import { FunctionComponent } from "react";

import { openExternalLink } from "../../services/Utils";

import "./WorkflowTask.scss";

interface IWorkflowTaskProps {
	date: string;
	activity: string;
	title: string;
	description: string;
	externalLink: string;
}

export const WorkflowTask: FunctionComponent<IWorkflowTaskProps> = (
	props: IWorkflowTaskProps
) => {
	const { date, activity, title, description, externalLink } = props;

	return (
		<div
			className="workflow-task"
			onClick={() => {
				openExternalLink(externalLink);
			}}
		>
			<div className="task-content">
				<div className="task-header">
					<div className="date">{date}</div>
					<div className="task-type">
						<Pill className="pill">{activity}</Pill>
					</div>
				</div>
				<div className="task-title">{title}</div>
				<div className="task-description">{description}</div>
			</div>
		</div>
	);
};
