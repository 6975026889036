import "./App.scss";
import { useTranslation } from "react-i18next";
import axios, { AxiosError } from "axios";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import { Errors } from "./components/errors/Errors";
import { WorkflowView } from "./views/workflowView/WorkflowView";

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "@o4c/plugin-components";
import { SynergyTask } from "./interfaces/synergyTask";

function App() {
	const [error, setError] = useState<boolean>(false);
	const [errorCode, setErrorCode] = useState<number | undefined>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const [loading, setLoading] = useState<boolean>(true);

	const [workflowTasks, setWorkflowTasks] = useState<SynergyTask[]>([])
	const [totalTasks, setTotalTasks] = useState<number>(0);
	const [synergyLink, setSynergyLink] = useState<string>("");
	const [taskPrefix, setTaskPrefix] = useState<string>("");

	const { t } = useTranslation();
	let location = useLocation();
	let query = new URLSearchParams(location.search);
	const token: string = query.get("token") || "";

	useEffect(() => {
		if (!token) {
			setErrorMessage(`Missing authorisation token.`);
			return setError(true);
		}
		setLoading(true);
		fetchSynergyData();

	}, []);

	const fetchSynergyData = async () => {
		const url = `/api/workflow/`;
		const headers = {
			Authorization: token
		}

		try {
			const { data } = await axios(url, { headers: headers });

			if (data !== "") {
				setWorkflowTasks(data.Items);
				setTotalTasks(data.totalTasks);
				setSynergyLink(data.globalUrl);
				setTaskPrefix(data.taskPrefix);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setError(true);

			if (error instanceof AxiosError) {
				setErrorCode(error?.response?.data.statusCode || 0);
				setErrorMessage(
					error?.response?.data.message ||
					`We are very sorry but we are unable to load your worklow.`
				);
			}

		}

	}

	return (
		<>
			{error ? (
				<Errors msg={errorMessage} errorCode={errorCode} />
			) : (
				<div className="container">
					<Header
						text={`${t("messages.workflow")} (${totalTasks
							})`}
					/>
					{loading ? (
						<Spinner type="dots" color="alert" size="default" />
					) : (
						<WorkflowView tasks={workflowTasks} prefix={taskPrefix} />
					)}
					<Footer
						text={`${t("messages.view all open tasks in synergy")}`}
						globalLink={synergyLink}
					/>
				</div>
			)}
		</>
	);
}

export default App;
