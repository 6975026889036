import { FunctionComponent } from "react"

import "./Header.scss"

interface IHeaderProps {
   text: string
}

export const Header: FunctionComponent<IHeaderProps> = (
   props: IHeaderProps
) => {

   const {text} = props

   return (
      <div className="workflow-header">
         {text}
      </div>
   )
}