import { FunctionComponent } from "react";
import { Button } from "@o4c/plugin-components";

import { openExternalLink } from "../../services/Utils";

import "./Footer.scss";

interface IFooterProps {
	text: string;
	globalLink: string;
}

export const Footer: FunctionComponent<IFooterProps> = (
	props: IFooterProps
) => {
	const { text, globalLink } = props;

	return (
		<div className="footer">
			<Button
				color="default"
				type="outline"
				style={{ borderRadius: 0 }}
				className="synergy-button"
				onClick={() => {
					openExternalLink(globalLink);
				}}
			>
				<div className="button-text">{text}</div>
			</Button>
		</div>
	);
};
