import { Icon } from "@o4c/plugin-components";
import { useTranslation } from "react-i18next";

import "./EmptyTaskList.scss";

export const EmptyTaskList = () => {
   const {t} = useTranslation()

	return (
		<>
			<div className="no-tasks-container">
				<div className="icon-wrapper">
					<Icon name="requests" />
				</div>
				<div className="info-text">{`${t("messages.no workflow")}`}</div>
			</div>
		</>
	);
};
